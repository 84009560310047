import { green, red } from '@mui/material/colors'

export const isProduction = import.meta.env.PROD

export const PORT = 3001
export const PRIMARY = '#59D5E0'
export const SECONDARY = '#F5DD61'

export const TIMEOUT_AXIOS_CALLS = 60 * 1000 // 60s

// 0 dispo, 1 pref, 2 indispo
export const INDISPO_AVAILABLE = 0
export const INDISPO_PREFERENCE = 1
export const INDISPO_NOT_AVAILABLE = 2

export const INDISPO_COLORS = {
  [INDISPO_AVAILABLE]: 'transparent',
  [INDISPO_PREFERENCE]: green[500],
  [INDISPO_NOT_AVAILABLE]: red[500],
}

// TOKENS Keys
export const KEY_ACCESS_TOKEN = 'ACCESS_TOKEN'
export const KEY_REFRESH_TOKEN = 'REFRESH_TOKEN'
export const KEY_MIDLIFE_TOKEN_DATE = 'MIDLIFE_TOKEN_DATE'
export const KEY_USER_ID = 'KEY_USER_ID'

export const SESSION_DEFAULT_URATION = 60 * 60 * 1000 * 1.75

// Rooms prefixes for socket.io
export const ROOM_PREFIX_SEPARATOR = '#'
export const ROOM_PREFIX_WEEK_EDIT = 'ROOM_WEEK_EDIT' + ROOM_PREFIX_SEPARATOR
export const ROOM_PREFIX_WEEK_VIEW = 'ROOM_WEEK_VIEW' + ROOM_PREFIX_SEPARATOR

export const ROOM_PREFIX_DAY_EDIT = 'ROOM_DAY_EDIT' + ROOM_PREFIX_SEPARATOR
export const ROOM_PREFIX_DAY_VIEW = 'ROOM_DAY_VIEW' + ROOM_PREFIX_SEPARATOR

// THeme
export const KEY_PREFERENCE_DARK_THEME = 'DARK_THEME'

// Date format
export const FORMAT_DD_MM_YYYY = 'DD-MM-YYYY'

const myIP = import.meta.env.VITE_TEST_MOBILE === 'true' ? '192.168.36.15' : 'localhost'
export const BASE_URL = isProduction ? `https://planning.thegame-france.com` : `http://${myIP}`

export const SOCKET_URL = isProduction
  ? `https://planning.thegame-france.com`
  : `http://${myIP}:${PORT}`

export const API_URL = isProduction
  ? `https://planning.thegame-france.com/api`
  : `http://${myIP}:${PORT}/api`

// Nombre max de mg nécessaires pour une matinée
export const MAX_MORNING_MG = 20

// mouse clicks event.button
export const RIGHT_CLICK = 2
export const LEFT_CLICK = 0

export const OBJECT_ID_REGEXP = /^[0-9a-fA-F]{24}$/

export const OTHER_HEADERS = [
  { initials: 'M', place_id: 1, order: 0, category: 'management' },
  { initials: 'R', place_id: 1, order: 1, category: 'runner' },
  { initials: 'F', place_id: 1, order: 3, category: 'formation' },
  { initials: 'M', place_id: 2, order: 0, category: 'management' },
  { initials: 'R', place_id: 2, order: 1, category: 'runner' },
  { initials: 'F', place_id: 2, order: 3, category: 'formation' },
  { initials: 'O', place_id: 2, order: 4, category: 'other' },
  { initials: 'O', place_id: 2, order: 5, category: 'other' },
]

export const THEME_DATA = {
  1: { name: 'Banque', color: '#fd7038' },
  2: { name: 'Casino', color: '#66243f' },
  3: { name: 'Enlèvement', color: '#d22841' },
  4: { name: 'Métro', color: '#3a9d8b' },
  5: { name: 'Catacombes', color: '#2b6c94' },
  6: { name: 'Assasin Creed', color: '#1c1a30' },
  7: { name: 'Avion', color: '#00adea' },
  8: { name: 'Mission Spatiale', color: '#f6b604' },
  9: { name: 'Le tremblement de terre', color: '#284920' },
  10: { name: 'Le cinéma', color: '#9d1a16' },
  11: { name: 'Le farwest', color: '#6b270c' },
  12: { name: 'Mission Secrète', color: '#aeabaa' },
}

// Couleurs utilisées pour les groupes ( dans le colorPicker )
// Elles sont dédoublées côté back pour la génération auto des groupes
export const GROUPS_COLORS = [
  '#FFB6C1',
  '#AFEEEE',
  '#90EE90',
  '#FFDAB9',
  '#ADD8E6',
  '#FFE4E1',
  '#FAFAD2',
  '#D8BFD8',
  '#F08080',
  '#B0E0E6',
  '#FFA07A',
  '#98FB98',
  '#87CEEB',
  '#DDA0DD',
  '#66CDAA',
  '#E6E6FA',
]

export const COMMENT_STYLES = [
  {
    type: 'Aucun',
    id: 'none',
    styles: {
      color: 'var(--mui-palette-text-primary)',
    },
  },
  {
    type: 'Formation',
    id: 'formation',
    styles: {
      color: 'rgb(255, 0, 0)',
      backgroundColor: 'rgb(255, 255, 0)',
      fontWeight: 'bold',
    },
  },
  {
    type: 'Event',
    id: 'event',
    styles: {
      color: 'rgb(0, 176, 80)',
      fontWeight: 'bold',
    },
  },
  {
    type: 'Aide aux pauses',
    id: 'breakHelp',
    styles: {
      color: '#C65911',
      fontWeight: 'bold',
    },
  },
  {
    type: 'Session de test',
    id: 'testSession',
    styles: {
      color: 'rgb(255, 101, 178)',
      fontWeight: 'bold',
    },
  },
  {
    type: 'Admin',
    id: 'admin',
    styles: {
      color: '#26456E',
      fontWeight: 'bold',
    },
  },
  {
    type: 'Urgences',
    id: 'emergency',
    styles: {
      color: 'black',
      backgroundColor: 'rgb(249, 58, 43)',
      fontWeight: 'bold',
    },
  },
  {
    type: "Point d'équipe",
    id: 'team',
    styles: {
      color: 'rgb(112, 48, 160)',
      fontWeight: 'bold',
    },
  },
  {
    type: 'Validation du planning de la veille',
    id: 'planningValidation',
    styles: {
      color: 'black',
      backgroundColor: 'rgb(146, 208, 80)',
    },
  },
  {
    type: 'Entretiens',
    id: 'entretiens',
    styles: {
      color: '#1795E3',
      fontWeight: 'bold',
    },
  },
]

// Additional sales timing (in ms)
export const ADDITIONAL_SALES_DATA_REFRESH_INTERVAL = 5_000
export const ADDITIONAL_SALES_PLANNING_VIEW_TIMEOUT = 60_000
export const ADDITIONAL_SALES_THEME_VIEW_TIMEOUT = 20_000
export const ADDITIONAL_SALES_PROMO_VIEW_TIMEOUT = 20_000